@import '~react-nice-dates/build/style.css';
// @import "~bootstrap/scss/bootstrap";
@import './assets/cosmos-theme.min.css';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.rdt_Table {
  .rdt_TableHead {
    [role='columnheader'] {
      color: #9692a5;
    }
    > div {
      height: 60px;
    }
  }
  .rdt_TableBody {
    .rdt_TableRow {
      height: 55px;
      background-color: #f6f6f6;
      .rdt_TableCell:first-child {
        text-decoration: underline;
        font-weight: bold;
        border: none !important;
      }
    }
  }
}

// -------------------------------

.page__title {
  display: flex;
  margin: 0;
  padding: 0;
  padding: 15px;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-weight: bold;
    margin: 0;
    padding: 0;
  }

  button {
    top: 106px;
    left: 1195px;
    text-transform: uppercase;
    width: 195px;
    height: 50px;
    background: transparent linear-gradient(180deg, #43d8c0 0%, #4abcc2 100%) 0%
      0% no-repeat padding-box;
    border-radius: 10px;
    color: white;
    border: none;
    outline: none;

    &:focus {
      background-color: white;
      border: none;
      box-shadow: none;
    }
  }
}

.table__container {
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    width: 100%;
    margin: 0;
    padding: 0;
    box-shadow: 0px 2px 2px #e4e4e4;
    border-radius: 5px 5px 0px 0px;
  }
  .rdt_Pagination {
    border-radius: 0px 0px 5px 5px;
  }

  .rdt_TableHeader .title {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    display: flex;
    justify-content: space-between;

    svg {
      cursor: pointer;
    }
  }
}
// -------------------------------
.form-group {
  label {
    font-weight: bold;
  }

  .css-2b097c-container,
  .css-14jk2my-container {
    .css-g1d714-ValueContainer {
      height: 48px;
      display: flex;
      flex-direction: inherit;
      .css-1uccc91-singleValue {
        position: absolute;
        top: 31px;
      }
      .css-1hb7zxy-IndicatorsContainer {
        height: 48px;
      }
    }
  }

  input,
  select,
  textarea {
    border: 1px solid #e2e2e2;
    border-radius: 5px;
    height: 48px;
    background: #f6f6f6 0% 0% no-repeat padding-box;
  }

  // .asyncselect_style div {
  //   height: 48px;
  // }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    background: url(../src/assets/chevron-down.svg) #f6f6f6 97% 50% no-repeat
      padding-box;
    cursor: pointer;
    option {
      font-size: 15px;
      padding: 10px;
    }

    &:after {
      content: '';
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5) !important;
}
