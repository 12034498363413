// :root {
//   --color-background: #FFF;
// }

// * {
//   margin: 0;
//   padding: 0;
//   box-sizing: border-box;
// }

// html, body, #root {
//   height: 100vh;
// }

// body {
//   background: var(--color-background);
// }

// #root {
//   background: #f0f2f5;
// }

// .bold {
//   font-weight: bold;
// }
.container-fluid.customized {
  margin-top: 80px;
  // margin-bottom: 80px;
  max-width: 1200px;
  // padding: 14px;
  // background: #fff;

  h3 {
    color: #7e7e82;
    padding: 11px;

    svg {
      cursor: pointer;
    }
  }
}

.table-buttons {
  button {
    border-radius: 10px;
    outline: none;
    color: #ffffff;
    text-transform: uppercase;
    border-color: transparent;

    &:active,
    &:focus,
    &:hover {
      background-color: transparent !important;
      border-color: transparent !important;
      outline: none !important;
      box-shadow: none;
    }
    &.edit {
      background: transparent linear-gradient(180deg, #43d8c0 0%, #4abcc2 100%)
        0% 0% no-repeat padding-box;
    }

    &.delete {
      background: transparent linear-gradient(180deg, #d84343 0%, #a82a2a 100%)
        0% 0% no-repeat padding-box;
    }
  }
}

html,
body,
#root {
  background: #f6f6f6;
}

form {
  .action-buttons {
    display: flex;
    justify-content: center;
    margin: 10px;

    button {
      margin: 5px;
    }
  }
  fieldset {
    border: 1px solid #efefef !important;
    padding: 15px !important;
    legend {
      font-size: 20px;
      color: #e0e0e0;
    }
  }
}
.form-control {
  &:focus {
    box-shadow: none !important;
    // background-color: #d6d6d61c !important;
    border: 2px solid #4ba2f9 !important;
  }
}
