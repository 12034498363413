.navbar.fixed-top {
  top: 0px;
  left: 0;
  height: 80px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #0000000f;

  > a.navbar-brand {
    color: #47c7c1;
  }

  .navbar-nav:nth-child(2) {
    align-items: center;
    .nav-link:nth-child(2) {
      width: 62px;
      height: 30px;
      border: 1px solid #47c7c1;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #47c7c1;
    }
  }
  .version {
    font-size: 10px;
    color: #00000042;
    font-weight: bold;
  }
}

@media (max-width: 991px) {
  .navbar.fixed-top {
    height: auto;
  }
}

.navbar.fixed-bottom {
  button {
    width: 115px;
    height: 50px;
    border: 1px solid #47c7c1;
    border-radius: 10px;
    opacity: 1;
    text-transform: uppercase;
    color: #fff;

    &.default {
      background-color: transparent;
      color: #47c7c1;
    }

    &.success {
      background: transparent linear-gradient(180deg, #43d8c0 0%, #4abcc2 100%)
        0% 0% no-repeat padding-box;
      border-radius: 10px;
      opacity: 1;
    }
  }
}
